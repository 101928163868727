import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Header from "./header"
import MobileMenu from './mobileMenu'
import "./layout.css"

const Layout = ({ Color, Imagen, Titulo, children }) => {
  let mainColor = Color ? Color : "#444444";

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          sliderImage1: file(relativePath: { eq: "slider-1.jpg" }){
            childImageSharp {
              fluid(maxWidth: 1920 quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          siteLogo: file(relativePath: { eq: "logo-2.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logoAcromatico: file(relativePath: { eq: "acromatico-logo.png" }){
            childImageSharp {
              fixed(width: 150){
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      `}
      render={ data => (
        <div>
          <header className="main-header">
            <Header MainColor={mainColor} SiteLogo ={data.siteLogo.childImageSharp.fluid} className="Header__Menu"/>
            <div className="Header__Image" style={{
              width: '100%',
              overflow: 'hidden' }}>
              <p style={{
                backgroundColor: mainColor
              }}>
                {Titulo ? Titulo : "Sin titulo"}
              </p>
              <Img fluid={Imagen ? Imagen : data.sliderImage1.childImageSharp.fluid} alt="Rafa y Mon" className="imagen-slider" style={{
                height: '100%' }} />
            </div>
          </header>
          <div className="Page__Content">
            <main>{children}</main>
          </div>
          <footer className="Page__Footer">
            © {new Date().getFullYear()}, Powered by
            {` `}
            <a href="https://acromatico.co" target="__blank">
              <Img fixed={data.logoAcromatico.childImageSharp.fixed} alt="Logo Acromático Development" style={{
                verticalAlign: "middle"
              }}/>
            </a>
          </footer>
          <MobileMenu />
        </div>
      ) }
    /> 
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
