import React, { Component } from "react"
import MensajesComponent from "rafa-mon-mensajes-component"
import PaymentsReactComponent from "payments-react-component"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Regalos extends Component {
  state = {
    hasMessage: false,
    messageContent: null,
    openCuenta: false
  }

  errorFunc = () => {
    this.setState({
      hasMessage: true,
      messageContent: (
        <div>
          <p>¡Hubo un Error!</p>
          <iframe title="gif where's my money" src="https://giphy.com/embed/1RkDDoIVs3ntm" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
        </div>
      )
    })
  }

  cancelFunc = () => {
    this.setState({
      hasMessage: true,
      messageContent: <iframe title="gif where's my money" src="https://giphy.com/embed/x3PfjsJCTBWGA" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    })
  }

  savedSuccess = (de, mensaje) => {
    const datosCorreo = {
      address: 'mongtp13@gmail.com',
      subject: 'Mensaje de Boda Rafa y Mon',
      body: `
        <h1 style="margin-bottom: 10px; font-size: 1.5rem;">Nuevo Mensaje De Felicitación por Boda</h1>
        <p style="margin-bottom: 10px; font-size: 1rem;"><strong>De:</strong> ${de}</p>
        <h3 style="margin-bottom: 10px; font-size: 1.3rem;">Mensaje</h3>
        <p>${mensaje}</p>
      `,
      noCopy: true
    };
  
    fetch('https://local-delivery-app.acromati.co/mail-sender/send', {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(datosCorreo), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    }).then(res => res.json()).then(resu => {
      console.log('Mensaje enviado con exito');
    }).catch(err => {
      console.log('Error: ', err);
    })
  }

  render(){
    let {data} = this.props;
    let mainColor = "#903B5A";
    let pageName ="Mesa de Regalos";
    let titleStyle = {
      backgroundColor: mainColor,
      color: "#ffffff",
      padding: "0.5em"
    }

    let paymentsComponent;
    let mesagesComponent;

    

    if(typeof window !== `undefined`){
      
      paymentsComponent = (
        <PaymentsReactComponent Color={mainColor} Modo="production" Gracias={(
          <div>
            <h4>¡Muchísimas Gracias por tu Regalo!</h4>
            <iframe title="Gracias gif" src="https://giphy.com/embed/l0HFkA6omUyjVYqw8" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
            <p>Gracias a ti estamos mucho más cerca de poder lograrlo. Te hemos hecho llegar tu recibo a tu correo :)</p>
          </div>
        )} onError={this.errorFunc} onCancel={this.cancelFunc} />
      );

      mesagesComponent = (
        <MensajesComponent onSavedMensaje={this.savedSuccess}>
          ¡Muchísimas gracias por tu mensaje! Gracias a Dios por tu vida y por compartir con nosotros este evento tan feliz.
        </MensajesComponent>
      );
    }

    return (
      <Layout Color={mainColor} Imagen={data.coverImage.childImageSharp.fluid} Titulo={pageName}>
        <SEO title={pageName} />
        <div className="Page__Text">
          <p>
            Como todos saben, llevamos varios años viviendo juntos, es por eso que en vez de pedir cositas para nuestro hogar, nos gustaría ver la posibilidad que nos pudieran apoyar económicamente para poder irnos de luna de miel. <span role="img" aria-label="Travel Emojis">🍯✈️🗺</span><br/><br/>
            ¡¡Nos encantaría visitar A S I A!! <span role="img" aria-label="Asia Emojis">🌏🐘🍚🥢📿💥</span><br/><br/>
            La idea es combinar un viaje turístico (recorrer algunos paises que nos encantaría conocer/volver) y terminar el viaje en un lugar típico lunamielero. Si Dios nos lo permite, hay 2 opciones de viaje que nos encantaría hacer:<br/>
          </p>
          <ol style={{
            width: "50%",
            margin: "20px auto",
            textAlign: "left"
          }}>
            <li><span role="img" aria-label="South East Asia Emojis">Myanmar🇲🇲| Tailandia🇹🇭| Camboya🇰🇭| Malasia🇲🇾| Singapur🇸🇬| Indonesia🇮🇩</span></li>
            <li><span role="img" aria-label="India Emojis">India🇮🇳| Nepal🇳🇵| Maldivas🇲🇻</span></li>
          </ol>
          <p>
            ¡Existen varios métodos muy sencillos para podernos hacer llegar tu regalo!, y puedes hacerlo directamente desde aquí mediante PayPal, Transferencia o Depósito. Igualmente si prefieres darnos tu regalo en efectivo, el día del evento habrá unos sobres donde podrán dejar su regalo <span role="img" aria-label="Love Emoji">💌</span>
          </p>
          <p className="negritas">NOTA: Esta sección permanecerá abierta hasta el 31 de Diciembre de 2019 para aquellos que no puedan aportar ahorita y quieran hacerlo más adelante.</p>
          <div className="Regalos__Container">
            <h4 className="colored-title" style={titleStyle}>Regalar con PayPal</h4>

            {paymentsComponent}

            {this.state.hasMessage && (
              <div style={{
                width: "60%",
                margin: "10px auto",
                color: "red"
              }}>
                {this.state.messageContent}
              </div>
            )}
            <h4 className="colored-title" style={titleStyle}>Regalar con Depósito / Transferencia</h4>
            <button onClick={() => this.setState({openCuenta: !this.state.openCuenta})}>Mostrar Número de Cuenta</button>
            {this.state.openCuenta && (
              <>
                <p>
                  Banco: BBVA Bancomer<br/>
                  Nombre: Mónica Denisse Gutiérrez Padilla<br/>
                  No. de Cuenta: 1407893663<br/>
                  CLABE: 0121 8001 4078 9366 31
                </p>
                <p>
                  Banco: Scotiabank<br/>
                  Nombre: Rafael González Vázquez<br/>
                  Cuenta: 25600698452<br/>
                  CLABE: 0441 8025 6006 9845 20
                </p>
              </>
            )}
            <h4 className="colored-title" style={titleStyle}>Regalar en Efectivo</h4>
            <p>El día de la boda habrá unos sobres donde podrán regalarnos en efectivo</p>
          </div>
          <br />
          <div className="Regalos__Container">
            <h4 className="colored-title" style={titleStyle}>Déjanos un Mensaje</h4>
            {mesagesComponent}
          </div>
          <p className="negritas">Importante: Esta página cuenta con todos los certíficados de seguridad web, por lo que es muy segura. Toda la información que ingreses está encriptada.</p>
        </div>
      </Layout>
    )
  }
}

export default Regalos

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "zebra.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;