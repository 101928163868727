import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import menuBars from '../components/bars-solid.svg';

const Header = ({ className, MainColor, SiteLogo }) => (
  <StaticQuery
  query={graphql`
    query SiteMenuQuery {
      allMenuDataJson {
        edges {
          node {
            title
            path
            color
          }
        }
      }
    }
  `}
    render={ data => (
      <div className={className} >
      <div className="Header__Menu-List">
        <ul className="desktop-menu">
          {data.allMenuDataJson.edges.map((elemento, index) => {
            return (
              <li key={index}><Link to={elemento.node.path} style={{
                color: elemento.node.color === MainColor ? MainColor : ""
              }}>{elemento.node.title}</Link></li>
            )
          })}
        </ul>

        <ul className="mobile-menu">
          <li>
            <img className="mobile-menu-image" src={menuBars} alt="menu bars" id="menuBars"/>
          </li>
        </ul>
      </div>
      <Link to="/" className="Header__Logo"><img srcSet={SiteLogo.srcSet}
        sizes={SiteLogo.sizes}
        src={SiteLogo.src} alt="Rafa y Mon Logo" /></Link>
    </div>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
